<template>
  <v-card class="px-4 pt-2">
    <v-data-table
      :headers="headers"
      :items="items"
      :page.sync="page"
      :itemsPerPage="itemsPerPage"
      :server-items-length="total"
      :loading="loading"
      @page-count="pageLength = $event"
      hide-default-footer
      disable-sort
      :mobile-breakpoint="0"
    >
      <template v-slot:[`item.action`]="{ item }">
        <Update :param="item" :callBack="showPage" />
        <Remove :param="item" :callBack="showPage" />
      </template>
      <template v-slot:footer>
        <v-divider />
        <v-row no-gutters dense align="center" class="py-2">
          <Save :callBack="showPage" />
          <v-spacer />
          <v-col cols="auto">
            <v-pagination
              v-model="page"
              :length="pageLength"
              total-visible="10"
              @input="changePage"
            />
          </v-col>
          <v-col
            cols="auto"
            class="text-center pl-4 pr-1"
            style="font-size: 16px; line-height: 40px;"
          >
            <span class="details">共{{ total }}条</span>
          </v-col>
          <v-col cols="auto" class="px-4">
            <v-select
              dense
              v-model="itemsPerPage"
              :items="pageSize"
              @change="changePageSize"
              hide-details="auto"
              class="pb-2"
              style="width: 100px"
            />
          </v-col>
        </v-row>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import Save from "./Save.vue";
import Update from "./Update.vue";
import Remove from "./Remove.vue";
export default {
  name: "Table",
  components: { Save, Update, Remove },
  data() {
    return {
      total: 0,
      items: [],
      loading: false,
      page: 1,
      itemsPerPage: 10,
      pageSize: [
        { text: "5条/页", value: 5 },
        { text: "10条/页", value: 10 },
        { text: "20条/页", value: 20 },
      ],
      searchParam: {},
      headers: [
        { text: "姓名", align: "center", value: "CNAME" },
        { text: "公司", align: "center", value: "COMPANYNAME" },
        { text: "性别", align: "center", value: "CSEX" },
        { text: "生日", align: "center", value: "CBIRTHDAY" },
        { text: "", value: "action", align: "center", width: "200" },
      ],
    };
  },
  computed: {},
  created() {
    this.showPage();
  },
  methods: {
    preShow() {},
    finishShow(data) {
      this.total = data.total;
      this.items = JSON.parse(data.data || "[]");
    },
    showPage() {
      this.loading = true;
      this.preShow();
      this.$api
        .postGet_GroupUser({
          page_number: this.page,
          page_size: this.itemsPerPage,
        })
        .then((res) => {
          let data = JSON.parse(res);
          if (data.code !== 0) {
            this.$notify.error({
              message: data.message,
            });

            return;
          } else {
            this.finishShow(data);
          }
        })
        .catch((error) => {
          this.$notify.error({
            message: error,
          });
          console.error(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    changePage(index) {
      this.page = index;
      this.showPage();
    },
    changePageSize(size) {
      this.itemsPerPage = size;
      this.showPage();
    },
  },
};
</script>
<style scoped>
.details {
  font-size: 15px;
  line-height: 45px;
  letter-spacing: 0.25rem;
}
</style>
