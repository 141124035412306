<template>
  <v-dialog v-model="dialog" max-width="300">
    <template v-slot:activator="{ on, attrs }">
      <slot name="activator" v-bind:on="on" v-bind:attrs="attrs">
        <!--暴露出来的slot，可以用自己的button自动替换该slot -->
        <v-btn icon color="primary" v-bind="attrs" v-on="on" class="ml-4">
          <v-icon>
            mdi-trash-can-outline
          </v-icon>
        </v-btn>
      </slot>
    </template>
    <v-card>
      <v-card-title> 删除{{ name }} </v-card-title>
      <v-card-text class="pb-0">
        是否确认删除？
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="primary" text @click="dialog = false">
          取消
        </v-btn>
        <v-btn color="primary" @click="commit" :loading="loading">
          删除
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "Remove",
  components: {},
  props: {
    callBack: Function,
    param: Object,
  },
  data() {
    return {
      name: "xxxx",
      dialog: false,
      loading: false,
      model: {
        id: "",
      },
    };
  },
  computed: {},
  watch: {
    dialog(val) {
      if (val) {
        let { id } = this.param;
        this.model = { id };
      }
    },
  },
  created() {},
  methods: {
    commit() {
      this.save();
    },
    save() {
      this.loading = true;
      this.$api
        .postDelete(this.model)
        .then((res) => {
          let data = JSON.parse(res);
          if (data.code !== 0) {
            this.$notify.error({
              message: data.message,
            });
            return;
          } else {
            this.$notify.success({
              message: data.message,
            });
            this.dialog = false;
            this.callBack && this.callBack();
          }
        })
        .catch((error) => {
          this.$notify.error({
            message: error,
          });
          console.error(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped></style>
