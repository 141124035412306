<template>
  <v-dialog v-model="dialog" max-width="450">
    <template v-slot:activator="{ on, attrs }">
      <slot name="activator" v-bind:on="on" v-bind:attrs="attrs">
        <!--暴露出来的slot，可以用自己的button自动替换该slot -->
        <v-btn small color="primary" v-bind="attrs" v-on="on" class="ml-4">
          新增{{ name }}
        </v-btn>
      </slot>
    </template>
    <v-card>
      <v-card-title> 新增{{ name }} </v-card-title>
      <v-card-text class="pb-0">
        <v-form v-model="valid" ref="form">
          <v-row no-gutters dense>
            <v-col cols="12">
              <v-text-field
                label="姓名"
                v-model="model.name"
                :rules="[(v) => !!v || '请填写姓名！']"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="primary" text @click="dialog = false">
          取消
        </v-btn>
        <v-btn color="primary" @click="commit" :loading="loading">
          新增
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "Save",
  components: {},
  props: {
    callBack: Function,
  },
  data() {
    return {
      name: "xxxx",
      dialog: false,
      valid: false,
      loading: false,
      model: {
        name: "",
      },
    };
  },
  watch: {
    dialog(val) {
      if (val) {
        this.$refs.form && this.$refs.form.reset();
        //this.$refs.form.resetValidation();
      }
    },
  },
  computed: {},
  created() {},
  methods: {
    commit() {
      if (!this.valid) {
        this.$refs.form.validate();
      } else {
        this.save();
      }
    },
    save() {
      this.loading = true;
      this.$api
        .postAdd(this.model)
        .then((res) => {
          let data = JSON.parse(res);
          if (data.code !== 0) {
            this.$notify.error({
              message: data.message,
            });
            return;
          } else {
            this.$notify.success({
              message: data.message,
            });
            this.dialog = false;
            this.callBack && this.callBack();
          }
        })
        .catch((error) => {
          this.$notify.error({
            message: error,
          });
          console.error(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped></style>
